import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import "./style.scss";
function Footer (){
    const location = useLocation();
    const navigate = useNavigate();
    const termsOfService =()=>{
        navigate('/termsOfService')
    }
    const privacyPolicy =()=>{
        navigate('/privacyPolicy')
    }
    const cancellationPolicy=()=>{
        navigate('/cancellation')
    }
    return(
        <>
       <div className='footer'>
            <ul>
                <li onClick={()=>termsOfService()}>
                    <a className={`${((location.pathname == '/signup') || (location.pathname == '/')) ? "styleColorWhite" : "styleColorBlack"}`} href="javascript:;">Terms of Service</a>
                </li>
                <li onClick={()=>privacyPolicy()}>
                    <a className={`${((location.pathname == '/signup') || (location.pathname == '/')) ? "styleColorWhite" : "styleColorBlack"}`} href="javascript:;">Privacy Policy</a>
                </li>  
                <li onClick={()=>cancellationPolicy()}>
                    <a className={`${((location.pathname == '/signup') || (location.pathname == '/')) ? "styleColorWhite" : "styleColorBlack"}`} href="javascript:;">Cancellation Policy111111</a>
                </li>               
            </ul>

           </div>
        </>
    )
}
export default Footer