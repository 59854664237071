import React, { Suspense, useContext } from "react";
import Header from "./Header";
import LinkHeader from "./LinkHeader";
import Sidebar from "./LinkHeader/Sidebar";
import TopHeader from "./LinkHeader/Top-header";
import Footer from "./Footer";
import "../../App.css";
import "./style.scss";
import {
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import { AppContext } from "../../config/context";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
}

function NonAuthLayout(props) {
  const { plansStatusWithoutLogin, setPlansStatusWithoutLogin } =
    useContext(AppContext);
  let location = useLocation();
  const isProductDetailLinkShare = location.pathname.startsWith(
    "/ProductDetailLinkShare/"
  );
  const wrapperClassName = [
    "/questions",
    "/refundPolicy",
    "/cancellation",
    "/plans",
    "/privacyPolicy",
    "/termsOfService",
  ].includes(location.pathname)
    ? "privacyPolicyWrapper"
    : "signupWrapper";

  if (localStorage.getItem("authorization") && !plansStatusWithoutLogin) {
    return (
      <Suspense fallback={null}>
        <Navigate
          to={{ pathname: "/dashboard", state: { from: props.location } }}
        />
      </Suspense>
    );
  }

  return (
    <>
      {isProductDetailLinkShare ? (
        // <div className="privacyPolicyWrapper">
        //   <div className="signupWrapper">
        //     <LinkHeader />
        //     {props.element}
        //   </div>
        // </div>

        //   <div className="PageWrapper">

        //     <Sidebar />
        //     <div className="ContentWrap" id="ContentWrap">
        //     <LinkHeader />
        //       {props.element}
        //     </div>

        // </div>

        <div className="PageWrapper">
          <Sidebar />
          <div className="ContentWrap" id="ContentWrap">
            <TopHeader />
            {props.element}
          </div>
        </div>
      ) : (
        <div className={wrapperClassName}>
          <div className="signupWrapper">
            <Header />
            {props.element}
            {location.pathname !== "/plans" && <Footer />}
          </div>
        </div>
      )}
    </>
  );

  // return (
  //   <div className={`${((location.pathname == '/questions') || (location.pathname == '/refundPolicy') || (location.pathname == '/cancellation') || (location.pathname == '/plans') || (location.pathname == '/privacyPolicy') || (location.pathname == '/termsOfService')) ? "privacyPolicyWrapper" : "signupWrapper"}`}>
  //   <div className="signupWrapper" >
  //     <Header />
  //     {props.element}
  //     {( props.router.location.pathname !== '/plans') ? (<>
  //       <Footer />
  //     </>) : ('')}

  //   </div>
  //   </div>
  // );

  // return (

  //   <div className="signupWrapper" >
  //     {props.element}
  //     {( props.router.location.pathname !== '/ProductDetailLinkShare/:productId/:teamId') ? (<>
  //       <LinkHeader />
  //     </>) : ('')}

  //   </div>
  // );
}
export default withRouter(NonAuthLayout);

// if(plansStatusWithoutLogin && localStorage.getItem('authorization')){
//   return (
//     <Suspense fallback={null}>
//       <Navigate
//         to={{ pathname: '/pricing' }}
//       />
//     </Suspense>
//   );
// }
